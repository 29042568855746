/* eslint-disable no-unused-vars */
import Layout from "components/layout";
import React, { useEffect, useState } from "react";
import FormHeader from "components/contentHeader/formHeader";
import { Container, Columns, Box, Button, Form } from "react-bulma-components";
import PersonalForm from "components/customerForms/ekycPersonalForm";
import FinancialDetailsForm from "components/customerForms/financialDetailsForm";
import RegulatoryRequirementsForm from "components/customerForms/regulatoryRequirementsForm";
import PermanentAddressDetailsForm from "components/customerForms/permanentAddressDetails";
import { isEmpty } from "@ramda/isempty/isEmpty";
import pick from "@ramda/pick";
import { connect } from "react-redux";
import { fetchCustomerDetail } from "actions/users";
import { useParams, Link, useHistory } from "react-router-dom";
import moment from "moment";
import { updateCustomerKyc } from "actions/kyc";
import { updateNotification } from "actions/notification";
import { fetchCummulativeLoanAmount } from 'actions/tickets';
import TicketList from "components/customerForms/ticketList";
import CumulativeBarGraph from "components/customerForms/cumulativeBarGraph";
import { relationshipFields } from "components/customerForms/fields/relationship.json";

// utils
import { jsonParse } from "utils/helper";

const { Column } = Columns;
const { Field } = Form;

const SupportCustomerForm = ({
  doFetchCustomerDetail,
  doupdateCustomerKyc,
  doUpdateNotification,
  dofetchCummulativeLoanAmount
}) => {
  const { userID } = useParams();
  const history = useHistory();

  const [loading, loadingHandler] = useState(true);
  const [isProcessing, isProcessingHandler] = useState(false);
  const [cummulativeLoan, cummulativeLoanHandler] = useState(0);

  //all fields are require
  // const optionalFields = {
  //   personalFormData: [
  //     "mName",
  //     "landLine",
  //     "spouseLName",
  //     "spouseFName",
  //     "spouseMName",
  //     "spouseDateOfBirth",
  //     "spousePlaceOfBirth",
  //     "spouseOccupation",
  //   ],
  // };

  const [errorFields, errorFieldsHandler] = useState({
    personalFormData: [],
    employmentData: [],
    financialData: [],
    addressData: [],
    pickupData: [],
    kycData: [],
    idData: [],
  });

  const [primaryDetails, primaryDetailsHandler] = useState({
    email: '',
    mobileNumber: '',
    fName: '',
    lName: '',
  })
  
  const [personalRefs, personalRefsHandler] = useState({});
  const [personalFormData, personalFormDataHandler] = useState({
    fName: "",
    mName: "",
    lName: "",
    nationality: "",
    birthDate: "",
    placeOfBirth: "",
    gender: "",
    kycFile: "",
    mobile: "",
    email: "",
    maritalStatus: "",
    citizenship: "",
    idType: "",
    idNumber: "",
    idValidity: "",
    tinIDNumber: "",
    sssgsisNumber: "",
    mobileNumber: "",
    landLine: "",
    spouseLName: "",
    spouseFName: "",
    spouseMName: "",
    spouseDateOfBirth: "",
    spousePlaceOfBirth: "",
    spouseOccupation: "",
  });

  const [employmentRefs, employmentRefsHandler] = useState({});
  const [employmentData, employmentDataHandler] = useState({
    sourceOfFunds: "",
    nameOfEmployer: "",
    natureOfBusiness: "",
    occupation: "",
    idRefNum: "",
    employmentStatus: "",
    yearWithEmployer: "",
    positionRank: "",
    grossMonthlyIncome: "",
    employerAddress: "",
    employersPhoneNumber: "",
    yearInBusiness: "",
    nameOfBusiness: "",
    businessAddress: "",
    businessLandline: "",
    pawnTransactionPurpose: "",
    isPoliticalRelationship: "",
    isPawnheroEmployeeAffiliate: "",
    phLName: "",
    phFName: "",
    phMName: "",
    phSuffix: "",
    phRelationship: "",
    isBeneficialOwnership: "",
    govt_id: "",
    manualKycForm: "",
    digiSignature: "",
  });

  const [relationFields, setRelationFields] = useState([relationshipFields]);
  const [politicalFields, setPoliticalFields] = useState([])

  const [politicalAffiliations, setPoliticalAffiliations] = useState({});

  const [isPoliticalRelationship, setIsPoliticalRelationship] = useState("no");
  const [customerRiskClass, setCustomerRiskClass] = useState("low");
  const [customerAutoRenewal, setCustomerAutoRenewal] = useState("false");
  const [isPawnheroEmployeeAffiliate, setIsPawnheroEmployeeAffiliate] =
    useState("no");
  const [isBeneficialOwnership, setIsBeneficialOwnership] = useState("no");

  const [regulatoryRequirementsDataRefs, regulatoryRequirementsDataRefsHandler] = useState({});
  const [regulatoryRequirementsData, regulatoryRequirementsDataHandler] = useState({
    politicalContainer: '',
    phLName: '',
    phFName: '',
    phMName: '',
    phSuffix: '',
    phRelationship: '',
    remarks: '',
    benificialOwnership: 'no',
    digiSignature: '',
    govtId: '',
    manualKycForm: '',
    customerRiskClass: 'low',
    customerAutoRenewal: false,
    customerUserId: 0
  })

  const [provinces, provincesHandler] = useState([]);
  const [cities, citiesHandler] = useState([]);
  const [barangays, barangaysHandler] = useState([]);

  const [addressRefs, addressRefHandler] = useState({});
  const [addressData, addressDataHandler] = useState({
    streetAddress: "",
    province: "",
    city: "",
    barangay: "",
    zipcode: "",
  });

  // const [pickupRefs, pickupRefsHandler] = useState({});
  // const [pickupData, pickupDataHandler] = useState({
  //   type: "Walk-in",
  //   streetAddress: "",
  //   province: "",
  //   city: "",
  //   barangay: "",
  //   dateReceive: "",
  //   rider: "",
  //   preferredDate: "",
  // });

  // const [kycRefs, kycRefsHandler] = useState({});
  const [kycData, kycDataHandler] = useState({
    file: [],
  });

  const [idRef, idRefHandler] = useState({});
  const [idDetails, idDetailsHandler] = useState({
    govt_id: false,
    type_id: "",
    valid_until: "",
    govt_number: "",
  });

  // const [ekycData, ekycDataHandler] = useState({
  //   itemName: "",
  //   itemModel: "",
  //   itemCategory: "",
  //   itemBrand: "",
  //   itemAttributes: "",
  // });

  const getData = async () => {
    loadingHandler(true);
    doFetchCustomerDetail(userID)
      .then((response) => {
        console.log("response_fetch_customer", response);
        const { data, success } = response;
        if (success) {
          const { user_data, customer_data, address_data, employment_data , affiliation_data} =
            data;
          const _personalFormData = {
            fName: user_data.first_name ? user_data.first_name : "",
            mName: user_data.middle_name ? user_data.middle_name : "",
            lName: user_data.last_name ? user_data.last_name : "",
            email: user_data.email ? user_data.email : "",
            mobileNumber: user_data.mobile ? user_data.mobile : "",
            nationality: customer_data.nationality_id
              ? customer_data.nationality_id
              : "",
            birthDate: customer_data.birthdate
              ? moment(customer_data.birthdate).toDate()
              : "",
            placeOfBirth: customer_data.birthplace,
            gender: customer_data.gender,
            customerRiskClass: customer_data.risk_type
              ? customer_data.risk_type
              : "",
            kycFile: customer_data.kyc_image,
            birthdate: customer_data.birthdate,
            birthplace: customer_data.risk_type ? customer_data.risk_type : "",
            maritalStatus: customer_data.marital_status
              ? customer_data.marital_status
              : "",
            citizenship: customer_data.citizenship
              ? customer_data.citizenship
              : "",
            idType: customer_data.type_id ? customer_data.type_id : "",
            idNumber: customer_data.id_number ? customer_data.id_number : "",
            landLine: customer_data.landline_number
              ? customer_data.landline_number
              : "",
            idValidity: customer_data.valid_until
              ? moment(customer_data.valid_until).toDate()
              : "",
            tinIDNumber: customer_data.tin_number
              ? customer_data.tin_number
              : "",
            sssgsisNumber: customer_data.sss_number
              ? customer_data.sss_number
              : "",
            spouseFName: customer_data.spouse_first_name
              ? customer_data.spouse_first_name
              : "",
            spouseLName: customer_data.spouse_last_name
              ? customer_data.spouse_last_name
              : "",
            spouseMName: customer_data.spouse_middle_name
              ? customer_data.spouse_middle_name
              : "",
            spouseDateOfBirth: customer_data.spouse_birthdate
              ? moment(customer_data.spouse_birthdate).toDate()
              : "",
            spousePlaceOfBirth: customer_data.spouse_birthplace
              ? customer_data.spouse_birthplace
              : "",
            spouseOccupation: customer_data.spouse_occupation
              ? customer_data.spouse_occupation
              : "",
          };

          personalFormDataHandler(_personalFormData);

          const _employmentData = {
            sourceOfFunds: employment_data.fund_source
              ? employment_data.fund_source
              : "",
            nameOfEmployer: employment_data.employer
              ? employment_data.employer
              : "",
            // natureOfBusiness: employment_data.nature_of_business
            //   ? employment_data.nature_of_business
            //   : "",
            occupation: employment_data.emp_occupation ? employment_data.emp_occupation : "",
            idRefNum: employment_data.sss ? employment_data.sss : "",
            employmentStatus: employment_data.employment_status
              ? employment_data.employment_status
              : "",
            natureOfBusiness: employment_data.business 
              ? employment_data.business 
              : employment_data.self_business
              ? employment_data.self_business
              : "",
            yearWithEmployer: employment_data.emp_years
              ? employment_data.emp_years
              : "",
            positionRank: employment_data.emp_position
              ? employment_data.emp_position
              : "",
            grossMonthlyIncome: employment_data.emp_income
              ? employment_data.emp_income
              : employment_data.self_income
              ? employment_data.self_income
              : "",
            employerAddress: employment_data.emp_address
              ? employment_data.emp_address
              : "",
            employersPhoneNumber: employment_data.emp_phone_number
              ? employment_data.emp_phone_number
              : "",
            yearInBusiness: employment_data.self_years_in_business
              ? employment_data.self_years_in_business
              : "",
            nameOfBusiness: employment_data.self_business_name
              ? employment_data.self_business_name
              : "",
            businessAddress: employment_data.self_address
              ? employment_data.self_address
              : "",
            businessLandline: employment_data.self_phone_number
              ? employment_data.self_phone_number
              : "",
            pawnTransactionPurpose: employment_data.pawn_purpose
              ? employment_data.pawn_purpose
              : "",   
            othersSourceFunds: employment_data.other_source_of_funds
            ? employment_data.other_source_of_funds
            : "",
            othersEmploymentStatus: employment_data.other_employment_status
            ? employment_data.other_employment_status
            : "",
            otherNatureBusiness: employment_data.other_nature_of_business
            ? employment_data.other_nature_of_business
            : "",
            otherPawnTransactionPurpose: employment_data.other_pawn_purpose
            ? employment_data.other_pawn_purpose
            : "",
          };
          employmentDataHandler(_employmentData);

          let _govt_id = ""
          if (customer_data.govt_id && customer_data.govt_id !== "NULL") {
            _govt_id = jsonParse(customer_data.govt_id).map(function (obj) {
              obj["src"] = obj["img"] || obj["src"];
              delete obj["img"];
              return obj;
            });
          }
          const _regulatoryRequirementsData = {
            politicalContainer : affiliation_data || '',
            isPoliticalAffiliations: affiliation_data.political_affiliations ? affiliation_data.political_affiliations : false,
            politicalAffiliations: affiliation_data.political_affiliations_list ? jsonParse(affiliation_data.political_affiliations_list) : '',
            phLName: affiliation_data.ph_last_name || '',
            phFName: affiliation_data.ph_first || '',
            phMName: affiliation_data.ph_middle_name || '',
            phSuffix: affiliation_data.ph_suffix || '',
            phRelationship: affiliation_data.ph_via || '',
            benificialOwnership: affiliation_data.benificial_ownership || 'no',
            remarks: customer_data.admin_remarks || '',
            govtId: _govt_id,
            manualKycForm: jsonParse(customer_data.kyc_image),
            digiSignature: customer_data.digital_signature ? jsonParse(customer_data.digital_signature) : '',
            customerRiskClass: customer_data.risk_type
              ? customer_data.risk_type
              : "low",
            customerAutoRenewal: user_data.auto_renewal
              ? "true"
              : "false",
            customerUserId: user_data.user_id || 0
          }
          regulatoryRequirementsDataHandler(_regulatoryRequirementsData);
          // const _regulatoryRequirements = {

          // }
          // employmentDataHandler(_regulatoryRequirements);

          // const _idDetails = {
          //   govt_id:
          //     (customer.govt_id &&
          //       customer.govt_id !== 'NULL' &&
          //       customer.govt_id) ||
          //     '',
          //   type_id: (!isNil(customer.type_id) && customer.type_id) || '',
          //   valid_until: customer.valid_until
          //     ? moment(customer.valid_until).toDate()
          //     : '',
          //   govt_number: customer.govt_number || '',
          // };

          // idDetailsHandler(_idDetails);

          // const _kycData = {
          //   file: [{ src: customer.kyc_image }],
          // };

          // kycDataHandler(_kycData);

          const _addressData = {
            streetAddress: address_data.address || "",
            province: address_data.province || "",
            city: address_data.city || "",
            barangay: address_data.barangay || "",
            zipcode: address_data.zipcode || "",
          };

          addressDataHandler(_addressData);
        }

        loadingHandler(false);
      })
      .catch((err) => {
        console.log("err", err);
        loadingHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    dofetchCummulativeLoanAmount(userID)
    .then((response) => {
      const { data = [], success } = response;

      if (success) {
        if (data.amount && data.amount > 0) {
          cummulativeLoanHandler(new Intl.NumberFormat('en-US').format(data.amount));
        }
      } else {
        console.log('something went wrong for getting cummulative loan amount');
      }
    })
    .catch((err) => {
      console.log('err', err);
    });

    if (!cancel) {
      getData();
    }

    return () => (cancel = true);
    //eslint-disable-next-line
  }, []);

  const makeUpdate = async () => {
    const { streetAddress, barangay, city, province, zipcode } = addressData;
    const {
      nameOfEmployer,
      occupation,
      sourceOfFunds,
      // idRefNum,
      employmentStatus,
      natureOfBusiness,
      yearWithEmployer,
      positionRank,
      grossMonthlyIncome,
      employerAddress,
      employersPhoneNumber,
      yearInBusiness,
      nameOfBusiness,
      businessAddress,
      businessLandline,
      pawnTransactionPurpose,
      // isPoliticalRelationship,
      isPawnheroEmployeeAffiliate,
      othersEmploymentStatus,
      othersSourceFunds,
      otherNatureBusiness,
      otherPawnTransactionPurpose,
    } = employmentData;

    const {
      birthDate: birthdate,
      placeOfBirth,
      gender,
      // nationality: nationality_id,
      fName,
      lName,
      mName,
      maritalStatus,
      citizenship,
      idType,
      idNumber,
      idValidity,
      tinIDNumber,
      sssgsisNumber,
      // mobileNumber,
      landLine,
      // email,
      spouseLName,
      spouseFName,
      spouseMName,
      spouseDateOfBirth,
      spousePlaceOfBirth,
      spouseOccupation,
    } = personalFormData;

    const {
      // politicalContainer,
      politicalAffiliations,
      phLName,
      phFName,
      phMName,
      phSuffix,
      phRelationship,
      benificialOwnership,
      remarks,
      digiSignature,
      govtId,
      manualKycForm,
      customerRiskClass,
      customerAutoRenewal,
    } = regulatoryRequirementsData
    // const { file = [] } = kycData;

    // const { type_id, valid_until, govt_number } = idDetails;
    console.log("manualKycForm",manualKycForm)
    const _payload = {
      user_id: parseInt(userID),
      customer_data: {
        admin_remarks: remarks,
        birthdate: birthdate,
        birthplace: placeOfBirth,
        gender,
        marital_status: maritalStatus,
        citizenship: citizenship,
        nationality_id: 1,
        type_id: idType,
        id_number: idNumber,
        govt_number: idNumber,
        valid_until: idValidity,
        tin_number: tinIDNumber,
        sss_number: sssgsisNumber,
        landline_number: landLine,
        spouse_first_name: spouseFName,
        spouse_last_name: spouseLName,
        spouse_middle_name: spouseMName,
        spouse_birthdate: spouseDateOfBirth,
        spouse_birthplace: spousePlaceOfBirth,
        spouse_occupation: spouseOccupation,
        address: streetAddress,
        barangay: barangay,
        city: city,
        province: province,
        zipcode: zipcode,
        fund_source: sourceOfFunds,
        employment_status: employmentStatus,
        employer: nameOfEmployer,
        business: natureOfBusiness,
        occupation_id: "",
        emp_years: yearWithEmployer,
        emp_occupation: occupation,
        emp_position: positionRank,
        emp_income: grossMonthlyIncome,
        emp_address: employerAddress,
        emp_phone_number: employersPhoneNumber,
        emp_business: natureOfBusiness,
        self_years_in_business: yearInBusiness,
        self_business_name: nameOfBusiness,
        self_income: grossMonthlyIncome,
        self_address: businessAddress,
        self_phone_number: businessLandline,
        self_business: natureOfBusiness,
        pawn_purpose: pawnTransactionPurpose,
        political_affiliations: !isEmpty(politicalAffiliations),
        political_affiliations_list: JSON.stringify([...new Set(politicalAffiliations)]),
        pawnhero_affiliations: isPawnheroEmployeeAffiliate,
        ph_last_name: phLName,
        ph_first: phFName,
        ph_middle_name: phMName,
        ph_suffix: phSuffix,
        ph_via: phRelationship,
        benificial_ownership: benificialOwnership,
        // govt_id: !isEmpty(govtId) ? govtId.map((e) => e.src).join("") : null,
        // kyc_image: !isEmpty(manualKycForm) ? manualKycForm.map((e) => e.src).join("") : null,
        // digital_signature: !isEmpty(digiSignature) ? digiSignature.map((e) => e.src).join("") : null,
        govt_id: JSON.stringify(govtId),
        kyc_image: JSON.stringify(manualKycForm),
        digital_signature: JSON.stringify(digiSignature),
        risk_type: customerRiskClass,
        other_employment_status: othersEmploymentStatus,
        other_source_of_funds: othersSourceFunds,
        other_nature_of_business: otherNatureBusiness,
        other_pawn_purpose: otherPawnTransactionPurpose,
      },
      user_data: {
        first_name: fName,
        last_name: lName,
        middle_name: mName,
        auto_renewal: customerAutoRenewal,
      },
    };
    isProcessingHandler(true);

    doupdateCustomerKyc(_payload)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          isProcessingHandler(false);
          doUpdateNotification({
            toggle: true,
            message,
            color: "primary",
          });
          history.push("/customer/customers");
        } else {
          //something wacky happen... haha contact tech support ^_^
          isProcessingHandler(false);
          doUpdateNotification({
            toggle: true,
            message,
            color: "danger",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        isProcessingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            "Something went wrong. Please refresh the page, or contact PawnHero IT support.",
          color: "danger",
        });
      });
  };
  return (
    <>
      <Layout>
        <FormHeader
          icon="https://d1v5w8bodpeh4i.cloudfront.net/b6618228cc984b92a0a2c406fbd55332.svg"
          link="/"
          viewOnly={true}
          disabledFlags={true}
          title={`${personalFormData.fName} ${personalFormData.mName} ${personalFormData.lName}`}
        />
        <Container>
          {loading ? (
            <>
              <div
                style={{
                  padding: "150px 0",
                }}
              >
                <img
                  src="https://d1v5w8bodpeh4i.cloudfront.net/assets/loader-elipse.svg"
                  alt="loading"
                  style={{
                    width: "32px",
                    height: "32px",
                    margin: "auto",
                    display: "flex",
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <Columns style={{ marginTop: "10px" }}>
                <Column size={6}>
                  <Box>
                    <PersonalForm
                      idData={idDetails}
                      idDataHandler={idDetailsHandler}
                      data={personalFormData}
                      dataHandler={personalFormDataHandler}
                      idRefHandler={idRefHandler}
                      kycDataHandler={kycDataHandler}
                      errFields={errorFields.personalFormData}
                      personalRefsHandler={personalRefsHandler}
                      idDetails
                      isReceiving={false}
                    />
                    <PermanentAddressDetailsForm
                      data={addressData}
                      dataHandler={addressDataHandler}
                      errFields={errorFields.addressData}
                      addressRefHandler={addressRefHandler}
                      isReceiving={false}
                      provinces={provinces}
                      cities={cities}
                      barangays={barangays}
                      provincesHandler={provincesHandler}
                      citiesHandler={citiesHandler}
                      barangaysHandler={barangaysHandler}
                    />
                    <FinancialDetailsForm
                      data={employmentData}
                      dataHandler={employmentDataHandler}
                      errFields={errorFields.employmentData}
                      employmentRefsHandler={employmentRefsHandler}
                      isReceiving={false}
                    />
                    <RegulatoryRequirementsForm
                      data={regulatoryRequirementsData}
                      dataHandler={regulatoryRequirementsDataHandler}
                      errFields={errorFields.regulatoryRequirementsData}
                      regulatoryRequirementsDataRefs={regulatoryRequirementsDataRefsHandler}
                      isReceiving={false}
                      kycDataHandler={kycDataHandler}
                      setRelationFields={setRelationFields}
                      setPoliticalFields={setPoliticalFields}
                      setPoliticalAffiliations={setPoliticalAffiliations}
                      setIsPoliticalRelationship={setIsPoliticalRelationship}
                      setCustomerRiskClass={setCustomerRiskClass}
                      setCustomerAutoRenewal={setCustomerAutoRenewal}
                      setIsPawnheroEmployeeAffiliate={setIsPawnheroEmployeeAffiliate}
                      setIsBeneficialOwnership={setIsBeneficialOwnership}
                      relationFields={relationFields}
                      politicalFields={politicalFields}
                      politicalAffiliations={politicalAffiliations}
                      isPoliticalRelationship={isPoliticalRelationship}
                      customerRiskClass={customerRiskClass}
                      customerAutoRenewal={customerAutoRenewal}
                      isPawnheroEmployeeAffiliate={isPawnheroEmployeeAffiliate}
                      isBeneficialOwnership={isBeneficialOwnership}
                    />
                    <Field style={{ marginTop: 0 }}>
                      <Column
                        style={{
                          marginTop: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button onClick={() => makeUpdate()}
                          disabled={isProcessing} color="success">
                          Update Customer
                        </Button>
                      </Column>
                    </Field>
                    <Field>
                      <Column
                        style={{
                          marginTop: "-1rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        >
                        <Link
                            to={`/customer/customers`}
                          >
                            <Button color="warning">
                              Back
                            </Button>
                          </Link>
                      </Column>
                    </Field>
                  </Box>
                </Column>
                <Column size={6}>
                  <Box>
                    { cummulativeLoan && (
                    <>
                      <Box>
                        <CumulativeBarGraph />
                      </Box>
                      <Box
                      style={{
                        background: '#354F65',
                        color: 'white',
                        textAlign: 'center',
                        marginBottom: '0.8rem'
                      }}>
                          <b>Cumulative Loan Amount :</b> ₱ {cummulativeLoan}
                      </Box>
                    </>
                    )
                  }
                    <TicketList />
                  </Box>

                </Column>
              </Columns>
              {/* <Columns>
                <Column size={12}>
                  <Button
                    color="primary"
                    size="small"
                    style={{ whiteSpace: "pre-line", height: "auto" }}
                    onClick={() => {
                      makeUpdate();
                    }}
                    disabled={isProcessing}
                  >
                    Update
                  </Button>

                  <Link
                    to={`/customer/customers`}
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <Button color="info" size="small">
                      cancel
                    </Button>
                  </Link>
                </Column>
              </Columns> */}
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchCustomerDetail: (payload) => dispatch(fetchCustomerDetail(payload)),
  doupdateCustomerKyc: (payload) => dispatch(updateCustomerKyc(payload)),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
  dofetchCummulativeLoanAmount: (payload) => dispatch(fetchCummulativeLoanAmount(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportCustomerForm);
